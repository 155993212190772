import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

export default {
  name: "whiteList",
  mixins: [mixins],
  data() {
    return {
      showImpDialog: false,
      inputFile: '',
      columns: [{
        label: '所属机构',
        prop: 'orgName'
      }, {
        label: '用户名称',
        prop: 'customerName'
      }, {
        label: '手机号',
        prop: 'phone'
      }, {
        label: '用户类型',
        prop: 'customerType'
      }, {
        label: '开始时间',
        prop: 'startTime'
      }, {
        label: '结束时间',
        prop: 'endTime'
      }, {
        prop: 'state',
        label: '状态'
      }, {
        prop: 'reason',
        label: '原因'
      }],
      topButtons: [{
        name: '新增',
        code: 'bikeWhiteList_add',
        type: 'primary',
        click: this.Add,
        permissions: ['bikeWhiteList_add']
      }],
      linkButtons: [{
        name: '失效',
        code: 'bikeWhiteList_del',
        type: 'danger',
        click: this.Del,
        permissions: ['bikeWhiteList_del'],
        show: row => {
          return row.state === '有效';
        }
      }, {
        name: '有效',
        code: 'bikeWhiteList_addWhite',
        type: 'danger',
        click: this.addWhite,
        permissions: ['bikeWhiteList_addWhite'],
        show: row => {
          return row.state === '失效';
        }
      }, {
        name: '编辑',
        code: 'bikeWhiteList_edit',
        click: this.Edit,
        permissions: ['bikeWhiteList_edit']
      }],
      // 搜索字段
      searchItems: [{
        prop: 'state',
        label: '状态',
        type: 'select',
        items: [{
          label: '有效',
          value: 0
        }, {
          label: '失效',
          value: 1
        }],
        itemProp: {
          label: 'label',
          value: 'value'
        }
      }, {
        prop: 'phone',
        label: '手机号'
      }]
    };
  },
  mounted() {
    this.$refs.tablePage.searchFormModel.state = 0;
  },
  methods: {
    Add() {
      this.$router.push('/bikeWhiteListAdd');
    },
    Edit(row) {
      this.$router.push({
        path: '/bikeWhiteListAdd',
        query: {
          id: row.id
        }
      });
    },
    Del(row) {
      ElMessageBox.confirm('确认把该白名单变更为失效吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.whiteList.del({
          id: row.id
        }).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    addWhite(row) {
      ElMessageBox.confirm('确认重新加入白名单吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.whiteList.addWhite({
          id: row.id
        }).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    downTemplate() {
      const loading = ElLoading.service();
      this.$api.business.whiteList.exportTemplate().then(data => {
        this.blobFile(data, '白名单导入模板文件.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {
          this.showImpDialog = false;
        });
      }).finally(() => {
        loading.close();
      });
    },
    fileChange(ev) {
      let selectFile = ev.target.files[0];
      if (selectFile) {
        let name = selectFile.name;
        let type = name.substring(name.lastIndexOf('.') + 1);
        if (type !== 'xls' && type !== 'xlsx') {
          this.$message.error('只能上传excel文件');
          this.inputFile = '';
        } else {
          this.inputFile = selectFile;
        }
      }
    },
    importInfo() {
      this.showImpDialog = true;
    },
    doImp() {
      if (!this.inputFile) {
        ElMessage.error('请选择文件');
        return;
      }
      const loading = ElLoading.service();
      // 构造一个 FormData，把后台需要发送的参数添加到FormData里面，这样就可以直接把formData作为参数传递了
      let formData = new FormData();
      formData.append('file', this.inputFile); //接口需要传递的参数
      this.$api.business.dataImportInfo.importWhiteFile(formData).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.inputFile = '';
          this.showImpDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};